.container {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-top: 1rem;
}

.btn {
    background-color: red;
    border-radius: 5;
}