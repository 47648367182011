.blackops_terminus_container {
  background-image: url('./img/background2.png');
  background-size: 100% 100%;
  min-height: 100vh;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.blackops_terminus_section {
  text-align: center;
  margin: 10px 0;
}

.blackops_terminus_grid {
	display: grid;
}

.blackops_terminus_grid-template-columns {
	grid-template-columns: repeat(3, 1fr);
}

@media screen and (min-width: 800px) {
  .blackops_terminus_grid-template-columns {
    grid-template-columns: repeat(6, 1fr);
  }
}

.blackops_terminus_item {
  padding: 2px;
	display: flex;
  align-items: center;
  justify-content: center;
}

.blackops_terminus_item_selected {
  border: red 2px solid;
}

.blackops_terminus_grid img {
  max-width: 50%;
  cursor: pointer;
}

.blackops_terminus_container pre {
  background: rgb(101, 99, 99);
  opacity: 0.8;
  text-align: center;
  font-size: 30px;
}

.blackops_terminus_section span {
  display: block;
  background: rgb(101, 99, 99);
  opacity: 0.8;
}
